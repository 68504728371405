import { createCancelToken } from '@apis/ServiceController';
import { message } from '@components/Message';
import { configurationService } from '@services/configurationService';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export const SettingInfoContext = React.createContext();
export const useSettingInfoContext = () => {
   return React.useContext(SettingInfoContext);
};

const getValues = (data) => {
   if (data) {
      return {
         ...data,
         banners: (data?.banners || []).map((i) => i.uid),
      };
   }
   return null;
};

const useSettingInfo = () => {
   const [loading, setLoading] = useState(false);
   const [values, setValues] = useState({});
   const [initialvalues, setInitialvalues] = useState({});
   const [submitting, setSubmitting] = useState(false);
   const submittingRef = useRef(false);

   const cancelToken = useRef();

   useEffect(() => {
      const loadData = async () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
         await setLoading(true);
         cancelToken.current = createCancelToken();
         const response = await configurationService.getConfigurationDetails(
            cancelToken.current?.token
         );
         if (response.isSuccess) {
            setInitialData(getValues(response.data));
         } else {
            message.error(response.message);
         }
         setLoading(false);
      };
      loadData();
      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, []);

   const setInitialData = (data) => {
      setValues({ ...data });
      setInitialvalues({ ...data });
   };

   const changeValues = useCallback((name, value) => {
      setValues((prevValues) => ({
         ...prevValues,
         [name]: value,
      }));
   }, []);

   const onSubmit = useCallback(async () => {
      if (submittingRef.current) {
         return;
      }
      console.log(values);
      submittingRef.current = true;
      await setSubmitting(true);
      const response = await configurationService.updateConfiguration(values);
      if (response.isSuccess) {
         if (response.data) {
            setInitialData(getValues(response.data));
            configurationService.loadConfiguration();
         }
         message.success('Successfully saved');
      } else {
         message.error(response.message);
      }
      setSubmitting(false);

      submittingRef.current = false;
   }, [values]);

   return {
      loading,
      values,
      initialvalues,
      submitting,
      onSubmit,
      setInitialData,
      changeValues,
   };
};

export default useSettingInfo;
