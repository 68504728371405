import React from 'react';

const IconSearch16 = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M13.5 7.25C13.5 10.7018 10.7018 13.5 7.25 13.5C3.79822 13.5 1 10.7018 1 7.25C1 3.79822 3.79822 1 7.25 1C10.7018 1 13.5 3.79822 13.5 7.25ZM12.0108 12.7179C10.7372 13.8278 9.07208 14.5 7.25 14.5C3.24594 14.5 0 11.2541 0 7.25C0 3.24594 3.24594 0 7.25 0C11.2541 0 14.5 3.24594 14.5 7.25C14.5 9.07208 13.8278 10.7372 12.7179 12.0108L15.8536 15.1464C16.0488 15.3417 16.0488 15.6583 15.8536 15.8536C15.6583 16.0488 15.3417 16.0488 15.1464 15.8536L12.0108 12.7179Z"
         fill="currentColor"
      />
   </svg>
);

export default IconSearch16;