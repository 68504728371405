import { Col, Row } from 'antd';
import React, { memo } from 'react';
import BannerSetting from './BannerSetting';

const SettingInfo = memo(() => {
   return (
      <div className="admin-event-details__body">
         <Row gutter={[30, 30]}>
            <Col lg={24} md={24} sm={24} xs={24}>
               <BannerSetting />
            </Col>
         </Row>
      </div>
   );
});

export default SettingInfo;
