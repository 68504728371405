import { AttachmentFileType } from '@apis/enums/AttachmentFileType';
import LazyImage from '@components/LazyImage';
import MediaPortal from '@components/MediaLibrary/MediaPortal';
import PopConfirm from '@components/PopConfirm';
import ResizeDetector from '@components/ResizeDetector';
import Skeleton from '@components/Skeleton';
import IconDelete2 from '@icons/IconDelete2';
import IconEdit from '@icons/IconEdit';
import { FileSize } from '@utils/enums/FileSize';
import useImgItem from '@views_admin/product-info/hooks/useImgItem';
import { StaticImage } from 'gatsby-plugin-image';
import React, { memo, useCallback, useState } from 'react';
import { useSettingInfoContext } from './hooks/useSettingInfo';

const imgDimension = 1110 / 480;
const mainImg = '../../assets/images/img-home1.png';

const MainContent = memo(
   ({ loading, value = [], changeValues, productUid, ...props }) => {
      const [isMediaOpen, setIsMediaOpen] = useState(false);
      const [containerWidth, setContainerWidth] = useState(0);

      const onChange = useCallback(
         (value) => {
            changeValues('banners', value);
         },
         [changeValues]
      );

      const removeImage = useCallback(() => {
         onChange([]);
      }, [onChange]);

      const onFinish = useCallback(
         (imgUids) => {
            console.log(imgUids);
            setIsMediaOpen(false);
            onChange(imgUids?.length ? [imgUids[0]] : []);
         },
         [onChange]
      );

      const onResize = useCallback((w) => {
         setContainerWidth(w);
      }, []);

      const displayImgId = value?.[0] || '';

      return (
         <div className="admin-card">
            <div className="admin-card__header">
               <label className="admin-card__header--title">Banner</label>
               <div className="admin-card__header--actions admin-setting__banner--actions">
                  <button
                     className="outline-btn btn-add-new-item"
                     onClick={() => setIsMediaOpen(true)}
                  >
                     <i>
                        <IconEdit />
                     </i>
                     Change Image
                  </button>
                  {displayImgId && (
                     <PopConfirm zIndex={4039} onConfirm={removeImage}>
                        <button className="outline-btn btn-add-new-item btn-delete">
                           <i>
                              <IconDelete2 />
                           </i>
                           Delete
                        </button>
                     </PopConfirm>
                  )}
               </div>
            </div>
            <div className="admin-card__body">
               <div className="admin-setting__banner">
                  <div
                     className="admin-setting__banner-container"
                     style={{ height: `${containerWidth / imgDimension}px` }}
                  >
                     {loading ? (
                        <Skeleton />
                     ) : (
                        <>
                           {!displayImgId ? (
                              <StaticImage
                                 className="main-img"
                                 placeholder="tracedSVG"
                                 src={mainImg}
                              />
                           ) : (
                              <ImgItem uid={displayImgId} onRemove={removeImage} />
                           )}
                        </>
                     )}
                  </div>
                  <ResizeDetector onResize={onResize} />
               </div>
            </div>
            <MediaPortal
               isOpen={isMediaOpen}
               initialType={AttachmentFileType.BANNERS}
               initialSelectedUids={value}
               onFinish={onFinish}
               singleSelect
            />
         </div>
      );
   }
);

const ImgItem = memo(({ uid, onRemove }) => {
   const { imgData, loading } = useImgItem({
      uid,
      onLostMediaData: onRemove,
   });

   return (
      <>
         {loading ? <Skeleton /> : <LazyImage src={imgData?.url} title={imgData?.name} />}
      </>
   );
});

const BannerSetting = memo((props) => {
   const { loading, values, changeValues } = useSettingInfoContext();
   return (
      <MainContent
         {...props}
         loading={loading}
         value={values?.banners}
         changeValues={changeValues}
      />
   );
});

export default BannerSetting;
