import { createCancelToken } from '@apis/ServiceController';
import { message } from '@components/Message';
import SEO from '@components/SEO';
import { configurationService } from '@services/configurationService';
import useSettingInfo, {
   SettingInfoContext,
} from '@views_admin/setting/hooks/useSettingInfo';
import SettingInfo from '@views_admin/setting/SettingInfo';
import React, { memo, useEffect, useRef, useState } from 'react';

const Setting = memo(() => {
   const { submitting, onSubmit, ...states } = useSettingInfo();

   return (
      <>
         <SEO title={'Setting - Admin'} />
         <SettingInfoContext.Provider
            value={{
               ...states,
            }}
         >
            <div className="admin-product-details admin-setting">
               <div className="content-head admin-product-details__header">
                  <div className="content-head__left">
                     <label className="title">Setting</label>
                  </div>
                  <div className="content-head__right admin-actions-group">
                     <button className="submit" onClick={onSubmit} disabled={submitting}>
                        Save
                     </button>
                  </div>
               </div>
               <div className="content-body">
                  <SettingInfo />
               </div>
            </div>
         </SettingInfoContext.Provider>
      </>
   );
});

export default Setting;
